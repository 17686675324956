"use client"

import { ComponentProps } from "react"
import Maintenance from "../../ui-lib/Maintenance"

export default function Error({
  error,
}: Pick<ComponentProps<typeof Maintenance>, "error">) {
  return <Maintenance error={error} />
}
